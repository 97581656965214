import React, {useCallback, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import {Auth} from "../services/auth";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import {Pages} from "../constants/pages";
import {Page} from "../components/page/page";

export const Login = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigateToRegister = useCallback(() => {
        history.replace(Pages.Register);
    }, [history])

    const onLogin = useCallback(async (event) => {
        event.preventDefault();
        setHasError(false);
        setIsLoading(true);
        try {
            await Auth.login(email, password);
            history.replace(`/${Auth.userUuid}`);
        } catch (e) {
            setHasError(true);
            setIsLoading(false);
        }
    }, [email, history, password]);

    return (
        <Page>
            <Container>
                <Row className="justify-content-center">
                    <Col md={4}>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>E-mailadres</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={email} onChange={(event => {
                                    setEmail(event.target.value);
                                    setHasError(false);
                                })}/>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Wachtwoord</Form.Label>
                                <Form.Control type="password" placeholder="Password" value={password} onChange={(event => {
                                    setPassword(event.target.value);
                                    setHasError(false);
                                })}/>
                            </Form.Group>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isLoading || !email || !password}
                                onClick={onLogin}>
                                { isLoading && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="mr-2"
                                    />
                                )}
                                Inloggen
                            </Button>
                            <Button variant="outline-primary" className="ml-2" onClick={navigateToRegister}>Registreren</Button>
                        </Form>
                        {hasError && (
                            <Alert variant="danger">De combinatie van e-mailadres en wachtwoord is niet geldig.</Alert>
                        )}
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}
