import React, {useCallback, useEffect, useState} from "react";
import {Db} from "../services/db";
import Form from "react-bootstrap/Form";
import moment from 'moment';
import Button from "react-bootstrap/Button";
import {ItemsTable} from "../components/table/table";
import {Col, Container, Row} from "react-bootstrap";
import {Auth} from "../services/auth";
import {useHistory} from 'react-router-dom';
import {Page} from "../components/page/page";

export const Zindelijkheid = () => {
    const history = useHistory();
    const [items, setItems] = useState({});
    const [addNeed, setAddNeed] = useState(false);
    const [pee, setPee] = useState(false);
    const [poo, setPoo] = useState(false);
    const [peeAccident, setPeeAccident] = useState(false);
    const [pooAccident, setPooAccident] = useState(false);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [puppies, setPuppies] = useState([]);
    const [puppy, setPuppy] = useState();

    useEffect(() => {
        Db.getPuppyNeedsRef(Auth.userUuid).on('value', (snapshot) => {
            setItems(snapshot.val());
        });

        const puppySub = Db.onPuppiesChange(Auth.userUuid, (puppies) => {
            setPuppies(puppies);
            setPuppy(puppies[0].id);
        });

        return () => puppySub.unsubscribe();
    }, []);

    const addPuppyNeed = useCallback(() => {
        setAddNeed(true);
        setDate(moment().format('YYYY-MM-DD'));
        setTime(moment().format('HH:mm'));
    }, []);

    const submitNeed = useCallback(async () => {
        const [hour, minutes] = time.split(':');
        let intHour = parseInt(hour);
        let intMinutes = parseInt(minutes);

        if (intMinutes <= 15) {
            intMinutes = 0;
        } else if (intMinutes <= 30 || intMinutes < 45) {
            intMinutes = 30
        } else {
            intHour = intHour + 1;
            intMinutes= 0
        }

        // fix for safari.. doesnt understand 8:30
        intHour = `0${intHour}`.slice(-2); // 08 = 08, 012 = 12
        intMinutes = `0${intMinutes}`.slice(-2);

        const dateTime = moment(`${date}T${intHour}:${intMinutes}`).toISOString();

        await Db.addPuppyNeed(pee, poo, peeAccident, pooAccident, dateTime, puppy, Auth.userUuid);
        setAddNeed(false);
        setPee(false);
        setPoo(false);
        setPuppy(puppies.length === 1 ? puppy : null);
        setPeeAccident(false);
        setPooAccident(false);
    }, [pee, poo, peeAccident, puppy, puppies, pooAccident, date, time]);

    const toHome = useCallback(async () => {
        history.replace('../')
    }, [history]);

    return (
        <Page>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        {addNeed ? (
                            <Form>
                                <Form.Group controlId="pee">
                                    <Form.Check type="checkbox" label="Geplast" onChange={(e) => {
                                        setPee(e.target.checked);
                                        if (!e.target.checked) {
                                            setPeeAccident(false);
                                        }
                                    }} />
                                </Form.Group>
                                <Form.Group controlId="poo">
                                    <Form.Check type="checkbox" label="Gepoept" onChange={(e) => {
                                        setPoo(e.target.checked);
                                        if (!e.target.checked) {
                                            setPooAccident(false);
                                        }
                                    }} />
                                </Form.Group>
                                { pee && (
                                    <Form.Group controlId="peeAccident">
                                        <Form.Check type="checkbox" label="Plas ongeluk?" onChange={(e) => setPeeAccident(e.target.checked)}/>
                                    </Form.Group>
                                )}
                                { poo && (
                                    <Form.Group controlId="pooAccident">
                                        <Form.Check type="checkbox" label="Poep ongeluk?" onChange={(e) => setPooAccident(e.target.checked)}/>
                                    </Form.Group>
                                )}
                                { puppies.length > 1 && (
                                    <Form.Group>
                                        <Form.Control as="select" onChange={(e) => setPuppy(e.target.value)}>
                                            {puppies.map((pup) => (
                                                <option key={pup.id} value={pup.id}>{pup.name}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                )}
                                <Form.Group controlId="date">
                                    <Form.Control type="date" label="Datum" value={date} onChange={(e) => setDate(e.target.value)}/>
                                </Form.Group>
                                <Form.Group controlId="time">
                                    <Form.Control type="time" label="Tijd" value={time} onChange={(e) => setTime(e.target.value)}/>
                                </Form.Group>
                            </Form>
                        ) : (
                            <ItemsTable items={items} puppies={puppies}/>
                        )}
                        { addNeed ? (
                            <>
                                <Button onClick={submitNeed} disabled={!pee && !poo}>Opslaan</Button>
                                <Button variant="danger" onClick={() => setAddNeed(false)}>Annuleren</Button>
                            </>
                        ) : (
                            <div className="d-flex justify-content-between">
                                <Button onClick={addPuppyNeed} className="mr-2">Voeg plasje/poepje toe</Button>
                                <Button variant="outline-primary" onClick={toHome}>
                                    Terug naar home
                                </Button>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}
