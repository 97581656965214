import React, {useCallback, useEffect, useState} from "react";
import moment from 'moment';
import Button from "react-bootstrap/Button";
import {Db} from "../../services/db";
import {Auth} from "../../services/auth";

const filterItemsByDay = (items, day) => {
  return Object.keys(items).map((key) => ({
      id: key,
      ...items[key]
  })).filter(item => {
      return moment(item.dateTime).isSame(day, 'day');
  }).sort((a, b) => {
      if (a.dateTime > b.dateTime) {
          return 1;
      }
      if (a.dateTime < b.dateTime) {
          return -1;
      }
      return 0;
  });
};

const itemsSortedByTime = (items) => {
    return items.reduce((total, item) => {
        const time = moment(item.dateTime).format('HH:mm');
        const prevItem = total[time];

        if (prevItem ){
            return {
                ...total,
                [time]: [...prevItem, item]
            }
        }
        return {
            ...total,
            [time]: [item]
        }
    }, {});
}

export const ItemsTable = ({items, puppies}) => {
    const filterFormat = 'YYYY-MM-DD 00:00:00'
    const today = moment().format(filterFormat);
    const [filterDay, setFilterDay] = useState(moment().format(filterFormat));
    const [groupedItems, setGroupedItems] = useState({});

    useEffect(() => {
        if (!items) {
            return;
        }
        const filteredItems = filterItemsByDay(items, filterDay);

        setGroupedItems(itemsSortedByTime(filteredItems));
    }, [items, filterDay]);

    const prevDay = useCallback(() => {
         setFilterDay(state => {
             return moment(state).subtract(1, 'days').format(filterFormat)
         });
    }, [])

    const nextDay = useCallback(() => {
        setFilterDay(state => {
            return moment(state).add(1, 'days').format(filterFormat)
        });
    }, []);

    const removeRow = useCallback((id) => {
        const isConfirmed = window.confirm('Weet je zeker dat je deze rij wilt verwijderen?');
        if (isConfirmed) {
            Db.removePuppyNeed(id, Auth.userUuid);
        }
    }, []);

    return (
        <>
            <div className="heading-wrapper">
                <h2 className="heading text-center">{moment(filterDay).format('DD MMM y')}</h2>
            </div>
            {Object.keys(groupedItems).length ? Object.keys(groupedItems).map((time) => (

                <div key={time} className="cleanliness-item">
                    <div className="cleanliness-item__time">
                        <strong className="heading">{time}</strong>
                    </div>
                    <div className="cleanliness-item__content">
                        {groupedItems[time].map((item) => (
                            <div key={item.id} className="cleanliness-item__content-item">
                                {(puppies.length > 1 && !!Db.findPuppy(puppies, item.puppyId)) && (
                                    <div className="cleanliness-item__puppy-name">
                                        {Db.findPuppy(puppies, item.puppyId).name}
                                    </div>
                                )}
                                {item.pee && (
                                    <div className="cleanliness-item__action">
                                        💦 Geplast {item.peeAccident && '(ongelukje)⚠️'}
                                    </div>
                                )}
                                {item.poo && (
                                    <div className="cleanliness-item__action">
                                        💩 Gepoept {item.pooAccident && '(ongelukje)⚠️'}
                                    </div>
                                )}
                                <Button className="cleanliness-item__delete" variant="outline-danger" size="sm" onClick={() => removeRow(item.id)}>&times;</Button>
                            </div>
                        ))}
                    </div>
                </div>
            ))  : (
                <div>Geen data bekend.</div>
            )}
            <div className="pt-2 pb-4">
                <Button variant="outline-primary" onClick={prevDay}>
                    Vorige dag
                </Button>
                {(moment(filterDay).diff(today, 'days') < 0) && (
                    <Button variant="outline-primary" onClick={nextDay}>
                        Volgende dag
                    </Button>
                )}
            </div>
        </>
    )
}
