import React, {useCallback, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import {Auth} from "../services/auth";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import {Pages} from "../constants/pages";
import {Page} from "../components/page/page";

export const Register = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const navigateToLogin = useCallback(() => {
        history.replace(Pages.Login);
    }, [history])

    const onRegister = useCallback(async (event) => {
        event.preventDefault();
        setError(undefined);
        setIsLoading(true);

        if (password !== repeatPassword) {
            setIsLoading(false);
            setError('Wachtwoorden zijn niet gelijk.')
            return;
        }

        try {
            await Auth.register(email, password);
            history.replace(`/${Auth.userUuid}`);
        } catch (e) {
            setError('Er ging iets mis tijdens het registeren.');
            setIsLoading(false);
        }
    }, [email, history, password, repeatPassword]);

    return (
        <Page>
            <Container>
                <Row className="justify-content-center">
                    <Col md={4}>
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>E-mailadres</Form.Label>
                                <Form.Control type="email" value={email} onChange={(event => {
                                    setEmail(event.target.value);
                                    setError(undefined);
                                })}/>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Wachtwoord</Form.Label>
                                <Form.Control type="password" minLength={6} value={password} onChange={(event => {
                                    setPassword(event.target.value);
                                    setError(undefined);
                                })}/>
                                <Form.Text className="text-muted">
                                    Minimaal 6 tekens.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Herhaal wachtwoord</Form.Label>
                                <Form.Control type="password"  value={repeatPassword} onChange={(event => {
                                    setRepeatPassword(event.target.value);
                                    setError(undefined);
                                })}/>
                            </Form.Group>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isLoading || !email || !password}
                                onClick={onRegister}>
                                { isLoading && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="mr-2"
                                    />
                                )}
                                Registreren
                            </Button>
                            <Button variant="outline-primary" className="ml-2" onClick={navigateToLogin}>Inloggen</Button>
                        </Form>
                        {error && (
                            <Alert variant="danger">{error}</Alert>
                        )}
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}
