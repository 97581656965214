import React from "react";
import Svg from '../../images/dog.svg'
import './icon.scss';

export const Icon = () => {
    return (
        <div className="icon">
            <div className="icon__circle">
                <img src={Svg} />
            </div>
        </div>
    )
}
