import React, {useCallback, useEffect, useState} from "react";
import {Db} from "../services/db";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Col, Container, Row} from "react-bootstrap";
import {Auth} from "../services/auth";
import {useHistory} from 'react-router-dom';
import ListGroup from "react-bootstrap/ListGroup";
import {Page} from "../components/page/page";

export const Puppies = () => {
    const history = useHistory();
    const [items, setItems] = useState({});
    const [name, setName] = useState('');

    useEffect(() => {
        const sub = Db.onPuppiesChange(Auth.userUuid, (puppies) => {
            setItems(puppies);
        });

        return () => {
            sub.unsubscribe();
        }
    }, []);

    const addPuppy = useCallback(() => {
        Db.addPuppy(name, Auth.userUuid);
        setName('');
    }, [name]);

    const toHome = useCallback(async () => {
        history.replace('../')
    }, [history]);

    return (
        <Page>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        {(!!items.length) && (
                            <>
                                <h2>Mijn puppies</h2>
                                <ListGroup>
                                    {items.map((puppy) => (
                                        <ListGroup.Item key={puppy.id}>{puppy.name}</ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </>
                        )}
                        <Form className="py-4">
                            <h5>Puppy toevoegen</h5>
                            <Form.Group controlId="puppyName">
                                <Form.Label>Puppy naam</Form.Label>
                                <Form.Control value={name} onChange={(e) => {
                                    setName(e.target.value);
                                }} />
                            </Form.Group>
                        </Form>
                        <Button onClick={addPuppy} disabled={!name}>
                            Puppy toevoegen
                        </Button>
                        <Button variant="outline-primary" onClick={toHome}>
                            Terug naar home
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}
