import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './scss/styles.scss';
import * as serviceWorker from './serviceWorker';
import {Redirect, Switch, Route, BrowserRouter} from "react-router-dom";
import {MyPuppyJournal} from "./pages/MyPuppyJournal";
import {Login} from "./pages/Login";
import {PrivateRoute} from "./components/private-route/Private-route";
import {Auth} from "./services/auth";
import moment from 'moment';
import 'moment/locale/nl';
import {Zindelijkheid} from "./pages/Zindelijkheid";
import {Puppies} from "./pages/Puppies";
import {Home} from "./pages/Home";
import {Register} from "./pages/Register";
import {Pages} from "./constants/pages";

moment.locale('nl');

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Switch>
              <PrivateRoute exact path={Pages.Login}>
                  <Login />
              </PrivateRoute>
              <Route path={Pages.Register}>
                  <Register/>
              </Route>
              <Route path={Pages.Logout} render={() => {
                  Auth.logout();
                  return <Redirect to={Pages.Login} />
              }} />
              <PrivateRoute path={`${Pages.Uid}${Pages.Puppies}`}>
                  <Puppies />
              </PrivateRoute>
              <PrivateRoute path={`${Pages.Uid}${Pages.Zindelijkheid}`}>
                  <Zindelijkheid />
              </PrivateRoute>
              <PrivateRoute path={Pages.Uid}>
                  <MyPuppyJournal />
              </PrivateRoute>
              <Route exact path="/">
                  {/*temp fix for not showing homepage*/}
                  <Redirect to={Pages.Login} />
              </Route>
              <Route path="*">
                  <Redirect to={Pages.Login} />
              </Route>
          </Switch>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
