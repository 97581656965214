import {Auth} from "../../services/auth";
import {Redirect, Route} from "react-router-dom";
import React, {useEffect, useState} from "react";

export const PrivateRoute = ({children, path, ...rest}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(undefined);

    useEffect(() => {
        const unsubscribe = window.firebase.auth().onAuthStateChanged(async(user) => {
            if (user) {
                Auth.userUuid = user.uid;
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        });
        return () => {
            unsubscribe();
        }
    }, [rest]);

    if (isAuthenticated === undefined) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={({location}) => {
                if (!isAuthenticated && location.pathname !== '/login') {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {from: location}
                            }}
                        />
                    )
                }

                if (isAuthenticated && location.pathname === '/login') {
                    return (
                        <Redirect
                            to={{
                                pathname: `/${Auth.userUuid}`,
                            }}
                        />
                    );
                }

               return children;
            }}
        />
    );
}
