import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import {Link} from "react-router-dom";
import {Auth} from "../services/auth";
import {Db} from "../services/db";
import {Page} from "../components/page/page";

export const MyPuppyJournal = () => {
    const [puppies, setPuppies] = useState([]);
    useEffect(() => {
        const sub = Db.onPuppiesChange(Auth.userUuid, setPuppies);
        return () => sub.unsubscribe();
    }, [])
    return (
        <Page>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <ListGroup>
                            <Link to={`/${Auth.userUuid}/mijn-puppies`}>
                                <ListGroup.Item>
                                    Mijn puppies
                                </ListGroup.Item>
                            </Link>
                            {!!puppies.length && (
                                <Link to={`/${Auth.userUuid}/zindelijkheid`}>
                                    <ListGroup.Item>
                                        Zindelijkheid
                                    </ListGroup.Item>
                                </Link>
                            )}
                            <Link to="/logout">
                                <ListGroup.Item>
                                    Uitloggen
                                </ListGroup.Item>
                            </Link>
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}
