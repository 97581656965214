import moment from 'moment';

export class Db {
    static _firebaseRef = window.firebase.database();
    static _puppyRefSub = null;
    static _puppySubCbs = [];
    static _puppies = [];

    static addPuppyNeed(pee, poo, peeAccident, pooAccident, dateTime, puppyId, userUuid) {
        const id = Db._firebaseRef.ref().child(`users/${userUuid}/pee-poo-items`).push().key;

        return Db._firebaseRef.ref().update({
            [`users/${userUuid}/pee-poo-items/${id}`]: {
                pee,
                poo,
                peeAccident,
                pooAccident,
                dateTime,
                puppyId
            }
        });
    }

    static removePuppyNeed(id, userUuid) {
        Db._firebaseRef.ref().child(`users/${userUuid}/pee-poo-items/${id}`).remove();
    }

    static getPuppyNeedsRef(userUuid) {
        return Db._firebaseRef.ref(`users/${userUuid}/pee-poo-items`)
    }

    static removePuppySubscription(index) {
        Db._puppySubCbs = Db._puppySubCbs.filter((cb, i) => i !== index);
    }

    static onPuppiesChange(userUuid, cb) {
        if (Db._puppyRefSub) {
            const length = Db._puppySubCbs.push(cb);
            cb(Db._puppies);
            return {
                unsubscribe: () => Db.removePuppySubscription(length - 1)
            }
        }

        Db._puppyRefSub = Db._firebaseRef.ref(`users/${userUuid}/puppies`).on('value', (snapshot) => {
            if (!!snapshot.val()) {
                Db._puppies = Object.keys(snapshot.val()).map((id) => ({
                    id,
                    ...snapshot.val()[id]
                }));

                Db._puppySubCbs.forEach((cb, index) => {
                    cb(Db._puppies, index);
                })
            }
        });

        Db._puppySubCbs.push(cb);

        return {
            unsubscribe: () => Db.removePuppySubscription(0)
        }
    }

    static findPuppy(puppies, id) {
        if (!puppies) {
            return null;
        }
        return puppies.find(puppy => puppy.id === id);
    }

    static addPuppy(name, userUuid) {
        const id = Db._firebaseRef.ref().child(`users/${userUuid}/puppies`).push().key;

        return Db._firebaseRef.ref().update({
            [`users/${userUuid}/puppies/${id}`]: {
                name,
                createdAt: moment().format()
            }
        });
    }
}
