import React from "react";
import {Icon} from "../icon/icon";

export const Page = (({children}) => {
    return (
        <div className="page py-3">
            <Icon/>
            {children}
        </div>
    )
})
