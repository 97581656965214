export class Auth {
    static _firebase = window.firebase.auth();
    static userUuid = '';

    static isAuthenticated() {
        return !!localStorage.getItem('zindelijkheids-app-user');
    }

    static async login(email, password) {
        // eslint-disable-next-line no-undef
        await this._firebase.setPersistence(window.firebase.auth.Auth.Persistence.LOCAL);
        const res = await this._firebase.signInWithEmailAndPassword(email, password);
        localStorage.setItem('zindelijkheids-app-user', res.user.uid);
        return res.user.uud;
    }

    static async register(email, password) {
        await this._firebase.setPersistence(window.firebase.auth.Auth.Persistence.LOCAL);
        const res = await this._firebase.createUserWithEmailAndPassword(email, password);
        console.log(res);
        localStorage.setItem('zindelijkheids-app-user', res.user.uid);
        return res.user.uud;
    }

    static async logout() {
        localStorage.removeItem('zindelijkheids-app-user');
        await this._firebase.signOut();
    }
}
